import {useEffect, useRef} from "react";
import {basicSetup} from "codemirror";
import {EditorState} from "@codemirror/state"
import {EditorView, lineNumbers} from "@codemirror/view"
import {javascript} from "@codemirror/lang-javascript";
import {oneDarkTheme} from "@codemirror/theme-one-dark";

function CodeEditor({file}) {
    const editorRef = useRef(null);
    useEffect(() => {
        if (!editorRef || !editorRef.current) {
            return;
        }

        const state = EditorState.create({
            doc: file.content,
            extensions: [basicSetup, javascript(), lineNumbers(), oneDarkTheme, EditorView.lineWrapping, EditorView.updateListener.of((v) => {
                if (v.docChanged) {
                    file.content = v.state.doc.toString();
                }
            })],
        });

        const view = new EditorView({
            state: state, parent: editorRef.current,
        });

        view.dom.style.height = "100%";
        console.log("peter xx ", window.getComputedStyle(view.dom).getPropertyValue('background-color'));
        return () => {
            view.destroy();
        };

    }, [file, file.content]);

    return (<div className="code-editor" ref={editorRef}>
    </div>);
}

export default CodeEditor;
