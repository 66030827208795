import React, { useEffect, useState } from 'react';
import FileTabs from './FileTabs';
import CodeEditor from './CodeEditor';
import "./App.css";
import RunWindowSheet from "./RunWindowSheet";
import { useParams } from "react-router-dom";
import DraftAPI from "./service/api/draft_api";
import { DraftBottomTabs } from "./DraftBottomTabs";
import { Message, Popup } from 'tdesign-mobile-react';
import GifRecorder from "./publish/GifRecorder";
import DraftInfoComp from "./publish/DraftInfoComp";
function DraftEditor() {

    const params = useParams();
    const draftId = params.draftId;

    const draftAPI = new DraftAPI();

    const [files, setFiles] = useState([{
        name: 'sketch.pde', content: '',
    },]);


    const [activeFileIndex, setActiveFileIndex] = useState(0);
    const handleFileClick = (index) => {
        setActiveFileIndex(index);
    };


    const [visibleRunPopWindow, setVisibleRunPopWindow] = useState(false);
    const [visibleGifPopWindow, setVisibleGifPopWindow] = useState(false);
    const [visibleInfoPopWindow, setVisibleInfoPopWindow] = useState(false);

    const handleVisibleGifPopChange = (visible) => {
        setVisibleGifPopWindow(visible)
    }

    // 发布信息页面
    const handleVisibleInfoPopChange = (visible) => {
        setVisibleInfoPopWindow(visible)
    }

    const handleVisibleRunPopChange = (visible) => {
        setVisibleRunPopWindow(visible)
    }

    const handleSaveButtonClick = async () => {
        await draftAPI.updateDraft(draftId, files);
        Message.success({
            content: '保存成功',
            theme: 'success',
        });
    }


    useEffect(() => {
        const fetchDraftData = async () => {
            const draftData = await draftAPI.getDraft(draftId);
            const files = draftData.data.files;
            setFiles(files);
        };

        fetchDraftData();
    }, [draftId]);

    return (<div className="project">

        <FileTabs
            files={files}
            activeFileIndex={activeFileIndex}
            onFileClick={handleFileClick}
        />
        <CodeEditor file={files[activeFileIndex]}></CodeEditor>
        <DraftBottomTabs
            handlerClickRunClick={() => handleVisibleRunPopChange(true)}
            handlerSaveClick={() => handleSaveButtonClick()}
            handlerPublishClick={() => handleVisibleGifPopChange(true)}
        ></DraftBottomTabs>

        <Popup visible={visibleRunPopWindow} onVisibleChange={handleVisibleRunPopChange} placement="bottom">
            <div className="vertical">
                <RunWindowSheet files={files}></RunWindowSheet>
            </div>
        </Popup>

        <Popup visible={visibleGifPopWindow} onVisibleChange={handleVisibleGifPopChange} placement="bottom">
            <div className="vertical">
                <GifRecorder files={files}></GifRecorder>
            </div>
        </Popup>

        <Popup visible={visibleInfoPopWindow} onVisibleChange={handleVisibleInfoPopChange} placement="bottom">
            <div className="vertical">
                <DraftInfoComp clickBackListener={() => handleVisibleInfoPopChange(false)}></DraftInfoComp>
            </div>
        </Popup>

    </div>);
}

export default DraftEditor;