import React, { useEffect, useRef } from 'react';
import { X } from "@phosphor-icons/react";

export default function RunWindowSheet({ onClose, files }) {
    const canvasRef = useRef(null);
    useEffect(() => {
        const canvas = canvasRef.current;
        const sources = files.map(file => file.content).join('\n');
        console.log('peter xxx ', sources);
        let processing = new window.Processing(canvas, sources);
        return () => {
            processing.exit();
        }
    }, [files]);

    return (<canvas ref={canvasRef} width={"100%"} height={"100%"}></canvas>);
}