import React from 'react';
import "./App.css";
import {ArrowSquareOut, Play} from "@phosphor-icons/react";

function BottomTabs({handlerClickRunClick}) {

    return (<div>
        <div className={"bottom-tabs"}>
            <div>
                <Play size={32} color={"green"} onClick={handlerClickRunClick}/>
                <div>Run</div>
            </div>

            <div>
                <ArrowSquareOut size={32} color={"white"}/>
                <div>发布</div>
            </div>

        </div>
    </div>);
}

export default BottomTabs;
