import {useEffect, useRef} from "react";
import gifshot from "gifshot";

export default function GifRecorder({files}) {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const sources = files.map(file => file.content).join('\n');
        let processing = new window.Processing(canvas, sources);

        let images = []; // 这个数组将包含我们要用来创建GIF的画布

        const captureInterval = setInterval(() => {
            const canvasCopy = document.createElement('canvas');
            const context = canvasCopy.getContext('2d');
            context.drawImage(canvas, 0, 0);
            images.push(canvasCopy.toDataURL('image/png'));
            if (images.length >= 30) {
                clearInterval(captureInterval);
                startRecording(images);
            }
        }, 100); // 每100毫秒捕获一次canvas的内容

        const startRecording = (images) => {
            gifshot.createGIF({
                gifWidth: canvas.width,
                gifHeight: canvas.height,
                interval: 0.1, // 每一帧多少秒
                numFrames: 30, // 帧数  30 * 0.1 = 3秒
                frameDuration: 0.1,
                keepCameraOn: false,
                images: images, // 使用我们创建的画布数组
                text: "",
                fontWeight: "normal",
                fontSize: "16px",
                fontFamily: "sans-serif",
                fontColor: "#ffffff",
                textAlign: "center",
                textBaseline: "bottom",
                sampleInterval: 1,
                numWorkers: 4,
            }, function (obj) {
                if (!obj.error) {
                    const image = obj.image; // 这是一个base64编码的GIF图像
                    const byteString = atob(image.split(',')[1]);
                    const mimeString = image.split(',')[0].split(':')[1].split(';')[0];
                    const ab = new ArrayBuffer(byteString.length);
                    const ia = new Uint8Array(ab);
                    for (let i = 0; i < byteString.length; i++) {
                        ia[i] = byteString.charCodeAt(i);
                    }
                    const blob = new Blob([ab], {type: mimeString});
                    const url = URL.createObjectURL(blob);
                    window.open(url);
                }
            });
        };

        return () => {
            processing.exit();
            clearInterval(captureInterval);
        }

    }, [files])

    return <>
        <canvas ref={canvasRef} width={"100%"} height={"100%"}></canvas>
    </>
}