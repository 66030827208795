import { useEffect, useState } from "react";
import { ArrowLeft, CaretRight } from "@phosphor-icons/react";

export default function DraftInfoComp({ clickBackListener }) {

    const [tags, setTags] = useState(['peter'])
    return <>
        <div class='flex flex-col p-4 space-y-4'>


            <div class='flex items-center space-x-4' onClick={clickBackListener}>
                <ArrowLeft size={32} />
                <p>发布作品</p>
            </div>

            <input placeholder="请添加作品描述" class='bg-gray-100 p-2 rounded-mg w-full h-24'></input>

            <div class='flex-row'>
                <div class='flex justify-between'>
                    <p>编辑标签</p>
                    <CaretRight size={24} />
                </div>


                <div class='flex flex-row flex-wrap gap-2 my-4'>
                    {
                        tags.map((tag, index) => {
                            return <div class='bg-gray-100 rounded-full px-4 py-2' key={index}>{tag}</div>
                        })
                    }
                </div>

            </div>

            <button class='bg-black text-white p-4 rounded-lg w-full flex-1'>发布</button>

        </div>
    </>;
}

