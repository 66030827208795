import {apiInstance} from "../core";

export default class DraftAPI {

    async getDraft(draftId) {
        return await apiInstance.get('getDraft', {
            params: {
                draftId
            }
        });
    }

    async updateDraft(draftId, files) {
        return await apiInstance.post('updateDraft', {
            draftId,
            files
        })
    }
}