import React from 'react';
import "./App.css";

function FileTabs({files, activeFileIndex, onFileClick}) {
    return (<div className="file-tabs">
        {files.map((file, index) => (<div
            key={index}
            className={`file-tab${index === activeFileIndex ? '-active' : ''}`}
            onClick={() => onFileClick(index)}
        >
            {file.name}
        </div>))}
    </div>);
}

export default FileTabs;
