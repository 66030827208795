import React from 'react';
import "./App.css";
import {ArrowSquareOut, FloppyDisk, Play} from "@phosphor-icons/react";

export function DraftBottomTabs({handlerClickRunClick, handlerSaveClick, handlerPublishClick}) {

    return (<div>
        <div className={"bottom-tabs"}>
            <div onClick={handlerClickRunClick}>
                <Play size={32} color={"green"}/>
                <div>Run</div>
            </div>

            <div onClick={handlerSaveClick}>
                <FloppyDisk size={32} color={"white"}/>
                <div>保存</div>
            </div>

            <div onClick={handlerPublishClick}>
                <ArrowSquareOut size={32} color={"white"}/>
                <div>发布</div>
            </div>


        </div>
    </div>);
}