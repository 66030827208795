import React, {useEffect, useRef} from 'react';
import {useParams} from "react-router-dom";
import SketchAPI from "./service/api/sketch_api";

export default function Preview() {
    const params = useParams();
    const sketchId = params.sketchId;
    const canvasRef = useRef(null);
    const processingRef = useRef(null);
    useEffect(() => {
        const fetchSketchData = async () => {
            const sketchData = await new SketchAPI().getSketch(sketchId);
            const canvas = canvasRef.current;
            const sources = sketchData.data[0].project[0].files[0];
            processingRef.current = new window.Processing(canvas, sources, () => {
                console.log("sketch loaded");
            });
        };

        fetchSketchData();

        return () => {
            if (processingRef.current) {
                processingRef.current.exit();
            }
        };
    }, [sketchId]);

    return (<div className="run-window-sheet-preview">
        <div className="run-window-sheet-content">
            <canvas ref={canvasRef} width={"100%"} height={"100%"}></canvas>
        </div>
    </div>);
}