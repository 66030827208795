import {apiInstance, client} from "../core";

export default class SketchAPI {

    async getSketch(sketchId) {
        return await apiInstance.get('getSketch', {
            params: {
                sketchId
            }
        });
    }
}