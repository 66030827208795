import React, {useState} from 'react';
import FileTabs from './FileTabs';
import CodeEditor from './CodeEditor';
import "./App.css";
import BottomTabs from "./BottomTabs";
import RunWindowSheet from "./RunWindowSheet";

function Project() {
    const [files, setFiles] = useState([{
        name: 'sketch.pde', content: 'code',
    },]);
    const [activeFileIndex, setActiveFileIndex] = useState(0);
    const handleFileClick = (index) => {
        setActiveFileIndex(index);
    };


    const [showRunWindowSheet, setShowRunWindowSheet] = useState(false);
    const handleRunButtonClick = (isOpen) => {
        setShowRunWindowSheet(isOpen);
    }

    return (<div className="project">
        <FileTabs
            files={files}
            activeFileIndex={activeFileIndex}
            onFileClick={handleFileClick}
        />
        <CodeEditor file={files[activeFileIndex]}></CodeEditor>
        <BottomTabs handlerClickRunClick={() => handleRunButtonClick(true)}></BottomTabs>
        {showRunWindowSheet && (
            <RunWindowSheet onClose={() => setShowRunWindowSheet(false)} files={files}></RunWindowSheet>)}

    </div>);
}

export default Project;