import React, {useEffect, useState} from 'react';
import FileTabs from './FileTabs';
import CodeEditor from './CodeEditor';
import "./App.css";
import BottomTabs from "./BottomTabs";
import RunWindowSheet from "./RunWindowSheet";
import {useParams} from "react-router-dom";
import SketchAPI from "./service/api/sketch_api";

function Editor() {

    const params = useParams();
    const sketchId = params.sketchId;

    const [files, setFiles] = useState([{
        name: 'sketch.pde', content: 'loading',
    },]);


    const [activeFileIndex, setActiveFileIndex] = useState(0);
    const handleFileClick = (index) => {
        setActiveFileIndex(index);
    };


    const [showRunWindowSheet, setShowRunWindowSheet] = useState(false);
    const handleRunButtonClick = (isOpen) => {
        setShowRunWindowSheet(isOpen);
    }


    useEffect(() => {
        const fetchSketchData = async () => {
            const sketchData = await new SketchAPI().getSketch(sketchId);
            const files = sketchData.data[0].project[0].files;
            const data = files.map((content, index) => {
                return {
                    name: 'sketch.pde', content: content,
                };
            });
            setFiles(data)
        };

        fetchSketchData();
    }, [sketchId]);

    return (<div className="project">
        <FileTabs
            files={files}
            activeFileIndex={activeFileIndex}
            onFileClick={handleFileClick}
        />
        <CodeEditor file={files[activeFileIndex]}></CodeEditor>
        <BottomTabs handlerClickRunClick={() => handleRunButtonClick(true)}></BottomTabs>
        {showRunWindowSheet && (
            <RunWindowSheet onClose={() => setShowRunWindowSheet(false)} files={files}></RunWindowSheet>)}

    </div>);
}

export default Editor;