import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Project from "./Project";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Preview from "./Preview";
import Editor from "./Editor";
import DraftEditor from "./DraftEditor";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<React.StrictMode>
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Project/>}/>
            <Route path="/preview/:sketchId" element={<Preview/>}/>
            <Route path="/edit/:sketchId" element={<Editor/>}/>
            {/*用于草稿的编辑*/}
            <Route path="/draft/:draftId" element={<DraftEditor/>}/>
        </Routes>
    </BrowserRouter>
</React.StrictMode>);